<div class="content-inner-deprecated pp-container">
  <div class="top-title">
    {{ 'book-appointment' | translate }}
  </div>
  <div ngClass.lt-lg="col-12" ngClass.gt-md="col-8">
    <pp-booking-summary-box
      [showConfirmButton]="loggedIn && !showPayment()"
      [confirmDisabled]="showLoader || (!paymentSuccess && showPayment())"
      [showLoader]="showLoader"
      (confirmClicked)="onConfirmClick()"
      title="{{ 'appointment-summary' | translate }}"
    >
    </pp-booking-summary-box>
    <div *ngIf="showPayment()">
      <div class="box">
        <div>
          <div class="mb-3">
            <h3 class="subtitle">{{ organization.cancellationPolicyTitle }}</h3>
            <div>
              {{
                'BillingInformation.CancelationFeeDescription'
                  | translate: { amount: service.cancellationFee, dueTime: this.organization.cancellationPeriod }
              }}
            </div>
            <div class="mt-2">
              {{ organization.cancellationPolicyDescription }}
            </div>
          </div>
          <up-fullsteam-control
            #fullSteamControl
            [showSaveButton]="false"
            [patientData]="patientData"
            [enableCardSelection]="loggedIn"
            (onSave)="onSave($event)"
            (cardSelectionChanged)="cardSelectionChanged($event)"
            (validationError)="validationError($event)"
            (fsError)="fsError($event)"
            (fsSucces)="fsSucces($event)"
          >
          </up-fullsteam-control>
        </div>

        <div class="mt-2" *ngIf="loggedIn">
          <div class="box message message-danger mb-4" role="alert" *ngIf="showError">
            <span class="wrap-icon-alert">
              <i class="material-icons warning-icon">warning</i>
            </span>
            {{ errorMessage | translate }}
          </div>
          <button class="btn btn-primary" [disabled]="showLoader" (click)="onConfirmClick()">
            <i class="fa fa-spin fa-circle-o-notch" *ngIf="showLoader"></i>
            {{ 'confirm-appointment' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!loggedIn">
      <div class="box select-box select-clinic-box">
        <form class="form-full-width" [formGroup]="additionalInfoForm" novalidate>
          <div class="row">
            <div class="col-lg-12 col-xs-12 form-row">
              <label class="label-form-control">{{ 'reason-for-visit' | translate }}</label>
              <textarea
                class="form-control form-control-lg"
                formControlName="reasonForVisit"
                (blur)="saveAditionalData()"
                name="reasonForVisit"
                placeholder="{{ 'reason-for-visit-placeholder' | translate }}"
                maxlength="200"
              ></textarea>
            </div>
            <div class="col-lg-12 col-xs-12 form-row">
              <label> {{ 'hear-about-us' | translate }}</label>
              <textarea
                class="form-control"
                formControlName="referal"
                name="referal"
                (blur)="saveAditionalData()"
                placeholder="{{ 'hear-about-us-placeholder' | translate }}"
                maxlength="100"
              ></textarea>
            </div>
            <div class="col-lg-12 col-xs-12 form-row" *ngIf="showSmsCheckbox">
              <div class="md-checkbox md-checkbox-reminder">
                <input
                  class="form-control form-control-lg"
                  formControlName="receiveSms"
                  name="receiveSms"
                  value=""
                  placeholder=""
                  type="checkbox"
                  id="1"
                />
                <label for="1" class="label-form-control label-checkbox label-terms"> {{ 'sms-reminder-checkbox' | translate }}* </label>
              </div>
            </div>
            <div class="col-12" *ngIf="showError">
              <div class="box message message-danger" role="alert">
                <span class="wrap-icon-alert">
                  <i class="material-icons warning-icon">warning</i>
                </span>
                {{ errorMessage | translate }}
              </div>
            </div>
            <div class="col-lg-12 col-xs-12 form-row">
              <button class="btn btn-primary" [disabled]="showLoader || (!paymentSuccess && showPayment())" (click)="onConfirmClick()">
                <i class="fa fa-spin fa-circle-o-notch" *ngIf="showLoader"></i>
                {{ 'confirm-appointment' | translate }}
              </button>
            </div>
            <div class="col-lg-12 col-xs-12 form-row" *ngIf="showSmsCheckbox">
              <p class="note">*{{ 'sms-reminder-explanation' | translate }}</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
